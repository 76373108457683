import React from "react";
import { SectionContainer } from "../components/section-container";
import { MainHeader } from "../components/main-header";
import { SubHeader } from "../components/sub-header";
import styled from "@emotion/styled";

import { Paragraph } from "../components/paragraph";
import { Row } from "../components/row";
import { Column } from "../components/column";

import { StatCircle } from "../components/stat-circle";
import { CircleContainer } from "../components/circle-container";

import { graphql, useStaticQuery } from "gatsby";

export default function OfferSection() {
  const data = useStaticQuery(graphql`
    {
      contentYaml(title: { eq: "qusec_numbers" }) {
        surface
        readers
        clients
      }
    }
  `);

  return (
    <>
      <SectionContainer sectionName="offer">
        <MainHeader>Jak działa Qusec</MainHeader>
        <SubHeader>
          Przygotowaliśmy dla Ciebie nowy System Wsparcia Usług Sprzątania -
          QUSEC firmy Klüh. Łączymy ponad 100 lat doświadczenia w branży
          facility management i nowoczesne technologie, żeby uczynić Twój obiekt
          bezpieczniejszym i atrakcyjniejszym.
        </SubHeader>
        <Row>
          <Column>
            <SecondaryHeader>
              QUSEC TO TRZY WSPÓŁPRACUJĄCE ZE SOBĄ MODUŁY
            </SecondaryHeader>
            <Paragraph>
              Czytnik kodów NFC - urządzenie, które rejestruje obecność
              pracownika Klüh w danym pomieszczeniu oraz przekazuje mu listę
              zadań do wykonania. To zastąpienie papierowych list kontrolnych
              czystości nowoczesną technologią.
            </Paragraph>
          </Column>
          <Column>
            <Paragraph>
              Czujnik ruchu - najlepsze rozwiązanie dla obszarów zagrożonych
              większym zabrudzeniem i ryzykiem epidemiologicznym. QUSEC
              monitoruje i analizuje ruch w pomieszczeniu i kiedy przekroczona
              zostanie krytyczna ilość odwiedzających, system informuje naszego
              pracownika o konieczności wyczyszczenia danego obszaru. Po
              posprzątaniu pomieszczenia pracownik Klüh, wykorzystując czytnik
              kodów NFC, potwierdzi QUSEC zrealizowanie zadania.
            </Paragraph>
            <Paragraph>
              Tablety - w kluczowych miejscach budynku - przed łazienkami, w
              kuchniach umieszczamy tablety z prostą w obsłudze aplikacją, która
              pozwoli użytkownikom budynku natychmiast skomunikować się z
              serwisem Klüh. W ten sposób można zgłosić brak kawy, konieczność
              wymiany żarówki czy rozlany w kuchni sok - wszystkie te drobne
              zdarzenia, które wpływają komfort pracy. Nasz serwis sprzątający
              szybko i sprawnie zareaguje na takie zgłoszenie.
            </Paragraph>
          </Column>
        </Row>
        <MainHeader>Co daje Qusec</MainHeader>
        <Row>
          <Column>
            <Paragraph>
              Wszystkie elementy systemu wsparcia usług sprzątania QUSEC
              pozwalają stale monitorować, analizować i usprawniać naszą pracę,
              dzięki raportom przekazywanym bezpośrednio do twojego panelu
              zarządzania. Dają także możliwość optymalizacji kosztów usługi.
            </Paragraph>
          </Column>
          <Column>
            <Paragraph>
              Systemy wsparcia to przyszłość w branży usług sprzątania. Klüh
              oferuje Ci tę przyszłość już dzisiaj. Dołącz do nas bo warto.
            </Paragraph>
          </Column>
        </Row>
        <CircleContainer>
          <StatCircle
            number={Number(data.contentYaml.surface)}
            title="m2 powierzchni obsługuje qusec"
          />
          <StatCircle
            number={Number(data.contentYaml.readers)}
            title="czytników kodów nfc analizuje i wspiera usługi KLÜH"
          />
          <StatCircle
            number={Number(data.contentYaml.clients)}
            title="klientów zdecydowało się na qusec"
          />
        </CircleContainer>
      </SectionContainer>
    </>
  );
}

const SecondaryHeader = styled.h2`
  font-family: "Lato";
  font-weight: bold;
  font-size: 30px;
  color: #303030;
  position: relative;

  border-bottom: 4px solid #f0f0f0;
  padding-bottom: 30px;

  @media (max-width: 450px) {
    font-size: 20px;
  }
`;
