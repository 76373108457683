import { useEffect, useState } from "react";

export function useDeviceDetect() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const userAgent =
      typeof navigator === "undefined" ? "" : navigator.userAgent;

    const mobile = Boolean(
      userAgent.match(
        /Android|BlackBerry|iPhone|iPad|iPod|OperaMini|IEMobile|WPDesktop/i
      )
    );

    setIsMobile(mobile);
  }, []);

  return { isMobile };
}
