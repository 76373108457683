import React from "react";
import styled from "@emotion/styled";

const FooterLayout = styled.div`
  width: 100%;
  height: 214px;

  background: ${props => props.theme.colors.red};

  display: flex;
  justify-content: center;
`;

const FooterContainer = styled.div`
  p,
  a {
    color: white;
    font-family: "Lato";
    font-weight: normal;
    font-size: 18px;
    text-decoration: none;
  }

  max-width: 1200px;
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  margin-left: 10px;
  margin-right: 10px;

  @media (max-width: 450px) {
    p,
    a {
      font-size: 14px;
    }
  }
`;

export default function Footer() {
  return (
    <FooterLayout>
      <FooterContainer>
        <p>
          Klüh Facility Services Sp. z o.o. ul. Grabiszyńska 251D, 53-234
          Wrocław, T: +48 71 7 97 46 30 M: qusec@klueh.com
        </p>
        <p>
          Copyright © 2010-2021 Klüh Facility Services Sp. z o.o.. All rights
          reserved.
        </p>
        <p>
          <a
            href="https://www.klueh.pl"
            target="__blank"
            style={{ fontWeight: 'bold' }}
          >
            www.klueh.pl
          </a>
        </p>
      </FooterContainer>
    </FooterLayout>
  );
}
