import React from "react";
import styled from "@emotion/styled";

export const Section = styled.div`
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
`;

export function SectionContainer({ sectionName, children }) {
  return (
    <div id={sectionName || ""}>
      <Section>{children}</Section>
    </div>
  );
}
