import React from "react";
import { SectionContainer } from "../components/section-container";
import { MainHeader } from "../components/main-header";
import { SubHeader } from "../components/sub-header";
import { Paragraph } from "../components/paragraph";
import { Row } from "../components/row";
import { Column } from "../components/column";

export default function AboutSection() {
  return (
    <SectionContainer sectionName="about">
      <MainHeader>O nas</MainHeader>
      <SubHeader>
        Jesteśmy Klüh. Od ponad 100 lat z powodzeniem działamy w branży Facility
        Management.
      </SubHeader>
      <Row>
        <Column>
          <Paragraph>
            Jesteśmy globalną marką obecną w całej Polsce. Stale się rozwijamy i
            ulepszamy nasze usługi. Naszym najnowszym produktem jest QUSEC.
            Jesteśmy przyjazną Klientowi firmą, skontaktuj się z nami, opowiemy
            ci o naszej ofercie i korzyściach jakie osiągniesz współpracując z
            nami.
          </Paragraph>
        </Column>
        <Column>
          <Paragraph>
            Dla Twojej wygody używamy łatwiejszej wersji naszej nazwy. Po prostu
            KLUEH /wymowa: kli/. Znajdziesz nas bez problemu.
          </Paragraph>
        </Column>
      </Row>
    </SectionContainer>
  );
}
