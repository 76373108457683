import React, { useState } from "react";
import styled from "@emotion/styled";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";

const Circle = styled.div`
  background-color: ${props => props.theme.colors.red};
  height: 260px;
  width: 260px;
  border-radius: 130px;
  font-family: "Gotham Bold";
  font-size: 36px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Title = styled.span`
  font-family: "Lato";
  font-weight: normal;
  color: #303030;
  font-size: 18px;
  text-transform: uppercase;
`;

const TitleContainer = styled.div`
  width: 260px;
  text-justify: center;
  text-align: center;
  margin-top: 25px;
`;

const StatLayout = styled.div`
  display: flex;
  margin-top: 55px;
  flex-direction: column;
`;

export function StatCircle({ number, title }) {
  const [didShow, setDidShow] = useState(false);

  function handleVisibileOnChange(isVisible) {
    return isVisible && setDidShow(true);
  }

  return (
    <StatLayout>
      <Circle>
        <VisibilitySensor
          offset={{ top: 200 }}
          delayedCall
          onChange={handleVisibileOnChange}
        >
          <CountUp start={0} end={didShow ? number : 0} separator=" " />
        </VisibilitySensor>
      </Circle>
      <TitleContainer>
        <Title>{title}</Title>
      </TitleContainer>
    </StatLayout>
  );
}
