import React from "react";
import styled from "@emotion/styled";
import image from "../../images/video_overlay-min.jpg";

const VideoOverlayStyled = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  .video-overlay {
    width: 100%;
  }
  z-index: 0;
`;

export default function VideoOverlay() {
  return (
    <VideoOverlayStyled className="video-overlay">
      <img className="video-overlay" src={image} alt="" />
    </VideoOverlayStyled>
  );
}
