import React from "react";
import styled from "@emotion/styled";
import Layout from "../components/layout";
import OfferSection from "../sections/offer";
import AboutSection from "../sections/about";
import ContactSection from "../sections/contact";
import Footer from "../components/footer";
import VideoContainer from "../components/video-container";

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
`;

function IndexPage() {
  return (
    <Layout>
      <VideoContainer />
      <Container>
        <OfferSection />
        <AboutSection />
        <ContactSection />
      </Container>
      <Footer />
    </Layout>
  );
}

export default IndexPage;
