import styled from "@emotion/styled";

export const CircleContainer = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  @media (max-width: 840px) {
    flex-direction: column;
    align-items: center;
  }
`;
