import React, { useState } from "react";
import styled from "@emotion/styled";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { css } from "@emotion/react";
import { useDeviceDetect } from "../hooks/use-device-detect";

const Circle = styled.div`
  height: 260px;
  width: 260px;
  border-radius: 50%;
  font-family: "Gotham Bold";
  font-size: 36px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .profile-photo {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    margin: 0;
    display: block;
  }
`;

const isMobileStyles = ({ isMobile }) => {
  return isMobile
    ? css`
        background-color: rgba(227, 33, 23);
      `
    : css`
        background-color: rgba(227, 33, 23, 0.85);
      `;
};

const Overlay = styled.div`
  background-color: rgba(227, 33, 23, 0);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  border-radius: 50%;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${isMobileStyles}

  transition: 0.15s;

  &:hover {
  }
`;

const VisibleOnHover = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 50%;

  ${Overlay} {
    opacity: 0;
  }

  &:hover {
    ${Overlay} {
      opacity: 1;
    }
  }
`;

const Text = styled.span`
  font-family: "Lato Bold";
  font-size: 17px;
  color: #fff;
`;

const ContactLayout = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 80px;
`;

const QusecIcon = styled.div`
  width: 40px;
  height: 40px;

  margin-bottom: 10px;
  .icon {
    width: 100%;
  }
`;

const MediaIconContainer = styled.div`
  display: flex;
  width: 165px;
  justify-content: space-around;
  margin-top: 15px;
  @media (min-width: 841px) {
    width: 135px;
  }
`;

const MediaIcon = styled.div`
  width: 45px;
  height: 45px;
  background-color: #ffffff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  .media-icon {
    width: 25px;
    height: auto;
    margin: 0;
  }
`;

function ConditionWrapper({ condition, children, wrapper }) {
  return condition ? wrapper(children) : children;
}

export function ContactCircle({
  photo,
  email,
  phone,
  linkedin,
  name,
  occupation,
}) {
  const [embedHref, setEmbedHref] = useState(false);
  const { isMobile } = useDeviceDetect();

  const data = useStaticQuery(graphql`
    {
      small_q: file(relativePath: { eq: "small_q.png" }) {
        childImageSharp {
          fluid {
            aspectRatio
            src
            srcSet
            sizes
          }
        }
      }
      email: file(relativePath: { eq: "email.png" }) {
        childImageSharp {
          fluid {
            aspectRatio
            src
            srcSet
            sizes
          }
        }
      }
      phone: file(relativePath: { eq: "phone.png" }) {
        childImageSharp {
          fluid {
            aspectRatio
            src
            srcSet
            sizes
          }
        }
      }
      linkedin: file(relativePath: { eq: "linkedin.png" }) {
        childImageSharp {
          fluid {
            aspectRatio
            src
            srcSet
            sizes
          }
        }
      }
    }
  `);

  const phoneMobile = phone?.replace(/\s+/g, "");

  return (
    <div style={{ borderRadius: "50%" }}>
      <ContactLayout>
        <Circle>
          {!isMobile && (
            <Img
              className="profile-photo"
              fluid={photo}
              alt="A corgi smiling happily"
            />
          )}
          <ConditionWrapper
            condition={!isMobile}
            wrapper={children => <VisibleOnHover>{children}</VisibleOnHover>}
          >
            <Overlay isMobile={isMobile}>
              <QusecIcon>
                <Img
                  className="icon"
                  fluid={data.small_q.childImageSharp.fluid}
                  alt="A corgi smiling happily"
                />
              </QusecIcon>
              <Text>{name}</Text>
              <Text>{occupation}</Text>
              {!isMobile && <Text>tel: +48 {phone}</Text>}
              <MediaIconContainer style={{ width: isMobile ? 180 : 135 }}>
                <MediaIcon
                  onMouseEnter={() => setEmbedHref(true)}
                  onMouseLeave={() => setEmbedHref(false)}
                >
                  <a href={embedHref ? `mailto:${email}` : ""}>
                    <Img
                      className="media-icon"
                      fluid={data.email.childImageSharp.fluid}
                      alt="email"
                    />
                  </a>
                </MediaIcon>
                {isMobile && (
                  <a href={`tel:+48${phoneMobile}`}>
                    <MediaIcon>
                      <Img
                        className="media-icon"
                        fluid={data.phone.childImageSharp.fluid}
                        alt="phone"
                      />
                    </MediaIcon>
                  </a>
                )}
                <MediaIcon>
                  <a href={`${linkedin}`} target="__blank">
                    <Img
                      className="media-icon"
                      fluid={data.linkedin.childImageSharp.fluid}
                      alt="linkedin"
                    />
                  </a>
                </MediaIcon>
              </MediaIconContainer>
            </Overlay>
          </ConditionWrapper>
        </Circle>
      </ContactLayout>
    </div>
  );
}
