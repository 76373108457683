import React from "react";
import { SectionContainer } from "../components/section-container";
import { MainHeader } from "../components/main-header";
import { ContactCircle } from "../components/contact-circle";
import { CircleContainer } from "../components/circle-container";
import { graphql, useStaticQuery } from "gatsby";

export default function ContactSection() {
  const { contact_1, contact_2, contact_3 } = useStaticQuery(graphql`
    {
      contact_1: allMarkdownRemark(
        filter: { frontmatter: { title: { eq: "contact_1" } } }
      ) {
        edges {
          node {
            id
            frontmatter {
              name
              photo {
                childImageSharp {
                  fluid {
                    base64
                    aspectRatio
                    src
                    srcSet
                    sizes
                  }
                }
              }
              occupation
              phone
              linkedin
              email
            }
          }
        }
      }

      # contact_2: allMarkdownRemark(
      #   filter: { frontmatter: { title: { eq: "contact_2" } } }
      # ) {
      #   edges {
      #     node {
      #       id
      #       frontmatter {
      #         name
      #         photo {
      #           childImageSharp {
      #             fluid {
      #               base64
      #               aspectRatio
      #               src
      #               srcSet
      #               sizes
      #             }
      #           }
      #         }
      #         occupation
      #         phone
      #         linkedin
      #         email
      #       }
      #     }
      #   }
      # }

      contact_3: allMarkdownRemark(
        filter: { frontmatter: { title: { eq: "contact_3" } } }
      ) {
        edges {
          node {
            id
            frontmatter {
              name
              photo {
                childImageSharp {
                  fluid {
                    base64
                    aspectRatio
                    src
                    srcSet
                    sizes
                  }
                }
              }
              occupation
              phone
              linkedin
              email
            }
          }
        }
      }
    }
  `);

  // const contactsMap = [contact_1, contact_2, contact_3];
  const contactsMap = [contact_1, contact_3];

  return (
    <SectionContainer sectionName="contact">
      <MainHeader>Kontakt</MainHeader>
      <CircleContainer style={{ marginBottom: 100 }}>
        {contactsMap.map((contact, index) => {
          const {
            name,
            phone,
            email,
            linkedin,
            occupation,
            photo,
          } = getContactProperties(contact);

          return (
            <ContactCircle
              name={name}
              email={email}
              phone={phone}
              linkedin={linkedin}
              occupation={occupation}
              photo={photo}
              key={index}
            />
          );
        })}
      </CircleContainer>
    </SectionContainer>
  );
}

function getContactProperties(contact) {
  const {
    name,
    email,
    phone,
    linkedin,
    occupation,
    photo,
  } = contact.edges[0].node.frontmatter;

  return {
    name,
    email,
    phone,
    linkedin,
    occupation,
    photo: photo.childImageSharp.fluid,
  };
}
