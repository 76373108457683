import React from "react";
import styled from "@emotion/styled";

export const SubHeaderLayout = styled.div`
  text-align: center;
  margin-bottom: 45px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  justify-content: center;
  span {
    display: inline-block;
    font-family: "Lato";
    font-weight: normal;
    font-size: 18px;
    color: #303030;
    max-width: 650px;
  }
`;

export function SubHeader({ children }) {
  return (
    <SubHeaderLayout>
      <span>{children}</span>
    </SubHeaderLayout>
  );
}
