import React, { useState } from "react";
import styled from "@emotion/styled";
import YouTube from "react-youtube";
import VideoOverlay from "../video-overlay";
import PlayButton from "../play-button";

const VIDEO_ID = "C6kNEdqc5Ks";

const VideoStyled = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  margin-top: 80px;

  display: flex;

  @media (max-width: 768px) {
    margin-top: 60px;
  }

  .video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const opts = {
  height: "100%",
  width: "100%",
  playerVars: {
    autoplay: 1,
    controls: 1,
    autohide: 1,
    showinfo: 1,
    wmode: "opaque",
    rel: 0,
    loop: 1,
    modestbranding: 1,
  },
};

export default function VideoContainer() {
  const [showVideo, setShowVideo] = useState(false);

  function handleShowVideo() {
    setShowVideo(true);
  }

  return (
    <VideoStyled>
      {showVideo && (
        <YouTube className="video" videoId={VIDEO_ID} opts={opts} />
      )}
      {!showVideo && (
        <>
          <PlayButton onClick={handleShowVideo} />
          <VideoOverlay />
        </>
      )}
    </VideoStyled>
  );
}
