import React from "react";
import styled from "@emotion/styled";

export const MainHeaderLayout = styled.div`
  h1 {
    font-family: "Gotham Medium";
    font-size: 40px;
    color: #303030;
    text-transform: uppercase;
  }
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 80px;
  margin-bottom: 15px;
  &:after {
    position: absolute;
    content: "";
    height: 4px;
    top: 58px;
    margin: 0 auto;
    left: 0;
    right: 0;
    width: 85px;
    background-color: ${props => props.theme.colors.red};
  }

  @media (max-width: 450px) {
    h1 {
      font-size: 30px;
    }
    &:after {
      top: 49px;
    }
  }
  @media (max-width: 340px) {
    h1 {
      font-size: 25px;
    }
  }
`;

export function MainHeader({ children }) {
  return (
    <MainHeaderLayout>
      <h1>{children}</h1>
    </MainHeaderLayout>
  );
}
